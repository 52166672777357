export * from './api.common';

export const API_DOMAIN = 'https://staging-test.studyiq.com/api';
export const PHP_API_BASE_URL = 'https://staging-test.studyiq.com/api/web/';
export const JAVA_API_BASE_URL = 'https://staging-backend.studyiq.net/';
export const JAVA_STORE_API_BASE_URL = 'https://staging-backend.studyiq.net/store-ws/';
export const IMAGE_PREFIX = 'https://staging-test.studyiq.com/images';
export const FRONTEND_DOMAIN = 'https://staging.studyiq.com';
export const DE_API_DOMAIN = 'https://dataengg-staging.studyiq.com';
export const JAVA_ANSWER_WRITING_API_BASE_URL =
  'https://assessment-service-api-stag.adda247.com/';
export const JAVA_QUESTION_SERVICE_API_BASE_URL =
'https://question-service-stag.adda247.com/';
export const JAVA_ANSWER_WRITING_ASSESSMENT_API_BASE_URL =
  'https://assessment-evaluation-api-stag.adda247.com/';
export const MEDIA_UPLOADER_API_DOMAIN =
  'https://media-uploader-stag.adda247.com';
export const LIVE_CLASS_STUDENT_JOIN_URL = 'https://staging.studyiq.com/adda-live-class';
export const CC_AVENUE_URL = 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction';
export const CC_AVENUE_ACCESS_CODE = 'AVHQ04JB16BJ11QHJB';

// Moengage
export const MOENGAGE_SDK_CONFIG = {
  app_id: 'KEOV3LEQVEDCYW3DTP9H7LTT',
  debug_logs: 1,
};

export const JAVA_API_TOKEN =
  '976997ED826BBF2F4B5E8D6E92F1C057FE3216EE522870BCF669A74A01E7FA77';

export const LEAD_SQUARED_BASE_URL = 'https://api-in21.leadsquared.com/v2/';
export const LEAD_SQUARED_ACCESS_KEY = 'u$r97865798689454f71d2b109b9d9a6b4e';
export const LEAD_SQUARED_SECRET_KEY = 'ff706084867a23832bbd05e8fa5c117bb9dea056';
export const LCS_SERVICE_BASE_URL = 'https://lcs-service-stag.adda247.com/v1/';
export const FIXED_MOCK_LAMBDA_API = 'https://dv9tgyvwl1q6b.cloudfront.net/fixed-mock';

export const FIREBASE_VAPID_KEY = 'BHtIyEtWdwfIP3D-5VQCqL8g-pUu2BcXuQ6NckYrILgLGp1OOte1GQVLzFHlx-DP_8EyTKRBb22uJp6XsYGsH_0';
export const NOTIFICATION_SERVICE_BASE_URL = 'https://notification-service-stag.adda247.com/v1/';
export const USER_AUTH_SERVICE_BASE_URL = 'https://user-auth-api-stag.adda247.com/v1/';

export const LANGUAGE_SERVICE_BASE_URL = 'https://languageai-api-dev.adda247.com/v2/';

export const STIQ_ROBOTS_URL = 'https://di2zh8iutt86v.cloudfront.net/robots.txt';

// Timeout Constant

export const TIMEOUT_DURATION = 5000;